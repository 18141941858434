import React, { useCallback, useEffect } from 'react';
import { Card, CardBody, CardTitle, Container, Input, Button, Alert, Spinner, FormGroup, Label } from 'reactstrap';
import Logo from '../../assets/images/bmm-logo.png';
import styles from '../../style/routes/login.css?url';
import { useNavigation, Form, useActionData, Link, useRouteLoaderData } from '@remix-run/react';
import { redirect, json } from '@remix-run/node';
import { getSession, commitSession } from '../../sessions';
import { loginAction } from './actions/loginAction';
import { resetPasswordAction } from './actions/resetPasswordAction';
import PasswordInput from '../../components/PasswordInput';
import { twoFactorLoginAction } from './actions/twoFactorLoginAction';

export const meta = () => {
  const pageTitle = `Login`;
  return [
    { title: `${pageTitle} | Best Money Moves` },
    {
      property: 'og:title',
      content: pageTitle,
    },
  ];
};

export const links = () => [{ rel: 'stylesheet', href: styles }];

export async function action({ request }) {
  const formData = await request.formData();

  const intent = formData.get('intent');

  const session = await getSession();

  switch (intent) {
    case 'login': {
      const loginResponse = await loginAction(formData, session);
      const loginData = await loginResponse?.json();

      if (loginData?.error) {
        return json(loginData);
      }

      if (loginData?.requires2fa) {
        return json(loginData);
      }

      return redirect('/', {
        headers: {
          'Set-Cookie': await commitSession(session),
        },
      });
    }
    case 'twoFactorLogin': {
      const loginResponse = await twoFactorLoginAction(formData, session);
      const loginData = await loginResponse?.json();

      if (loginData?.error) {
        return json(loginData);
      }

      return redirect('/', {
        headers: {
          'Set-Cookie': await commitSession(session),
        },
      });
    }
    case 'reset': {
      const resetResponse = await resetPasswordAction(formData);
      const resetData = await resetResponse?.json();

      return json(resetData);
    }
    default:
      return redirect('/', {
        headers: {
          'Set-Cookie': await commitSession(session),
        },
      });
  }
}

export default function Login() {
  const data = useActionData();
  const [error, setError] = React.useState(null);
  const { toast } = useRouteLoaderData('root');

  useEffect(() => {
    if (data?.error) {
      setError(data?.error);
    }
  }, [data]);

  useEffect(() => {
    if (toast?.message) {
      setError(toast?.message);
    }
  }, [toast]);

  const handleLoginClick = () => {
    setError(null);
  };

  const navigation = useNavigation();

  const userAgent = typeof window !== 'undefined' ? window.navigator?.userAgent : null;

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      {error && (
        <Alert color="danger" className="text-center w-100">
          {error}
        </Alert>
      )}
      {data?.message && (
        <Alert color="info" className="text-center w-100">
          {data?.message?.title && <h5 className="alert-heading">{data?.message?.title}</h5>}
          {data?.message?.body}
        </Alert>
      )}

      <Card className="w-100">
        <CardBody className="login-container my-3 d-flex flex-column align-items-center justify-content-around text-center">
          <img src={Logo} alt="Logo" />

          {!data?.requires2fa ? (
            <>
              <CardTitle tag="h2">Account Login</CardTitle>
              <p className="text-secondary">Find financial strength with the help of Best Money Moves.</p>
              <Form method="post" className="w-100 mt-5">
                <Input
                  className="text-center mb-4"
                  name="username"
                  id="username"
                  placeholder="Email, Mobile, or Username"
                />
                <PasswordInput
                  className="text-center mb-5"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                />
                <input hidden name="deviceId" id="deviceId" value={userAgent} />
                <Button
                  color="primary"
                  name="intent"
                  type="submit"
                  value="login"
                  className="mb-3 px-5"
                  style={{ width: '9rem' }}
                  onClick={handleLoginClick}
                >
                  {navigation.formAction === '/login' ? <Spinner size="sm" /> : 'Login'}
                </Button>
                <div className="d-flex align-items-center gap-3 w-100 justify-content-center mb-2">
                  <span>Need to signup?</span>
                  <Link to="/register">
                    <span>Register with your code!</span>
                  </Link>
                </div>
                <Button color="link" name="intent" type="submit" value="reset">
                  Forgot your password?
                </Button>
              </Form>
            </>
          ) : (
            <>
              <CardTitle tag="h2">Two-Factor Authentication</CardTitle>
              <p className="text-secondary">
                An authorization code has been sent to your verified mobile device. Please enter the code below.
              </p>
              <Form method="post" className="w-100 mt-5">
                <PasswordInput
                  className="text-center mb-4"
                  type="password"
                  name="authCode"
                  id="authCode"
                  placeholder="Authorization Code"
                />
                <input hidden name="deviceId" id="deviceId" value={userAgent} />
                <input hidden name="phone" id="phone" value={data?.phone} />
                <FormGroup check className="mb-4">
                  <Label check>
                    <Input type="checkbox" name="rememberDevice" /> Remember this device for 30 days
                  </Label>
                </FormGroup>
                <Button color="primary" name="intent" value="twoFactorLogin" className="mb-3 px-5">
                  {navigation.formAction === '/login' ? <Spinner size="sm" /> : 'Submit Code'}
                </Button>
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}
